<template>
  <v-sheet>
    <v-row class="px-3">
      <v-col cols="12" sm="10">
        <v-btn
          :block="$vuetify.breakpoint.xsOnly"
          depressed
          color="#04BF68"
          dark
          @click="showDialog()"
          >Add Category
        </v-btn>
      </v-col>
      <v-col cols="12" sm="2">
        <!-- <v-select
          :items="['Foo', 'Bar', 'Fizz', 'Buzz']"
          label="Filter"
          dense
          outlined
        /> -->
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="data"
          :loading="loading"
          :server-items-length="total"
          item-key="id"
          @pagination="pagination"
        >
          <template v-slot:[`item.icon`]="{ item }">
            <v-img
              :src="item.icon"
              class="ma-2"
              width="80"
              height="80"
              @click="
                () => {
                  viewImage = true;
                  urlFile = item.icon;
                }
              "
            />
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <!-- <v-btn color="#04BF68" fab dark depressed x-small>
              <v-icon>mdi-eye</v-icon>
            </v-btn> -->
            <v-btn
              color="primary"
              fab
              depressed
              x-small
              class="mx-2"
              @click="showDialog(item)"
            >
              <v-icon>mdi-pencil-box-outline</v-icon>
            </v-btn>
            <v-btn
              color="error"
              fab
              depressed
              x-small
              @click="deleteHandler(item)"
            >
              <v-icon>mdi-delete-forever</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogForm" persistent width="500">
      <v-card flat>
        <v-card-title class="grey">
          <div class="heading-4 font-weight-bold">
            {{ isEdit ? "Edit Category" : "Add Category" }}
          </div>
          <v-spacer />
          <v-icon class="font-weight-bold" @click="closeForm()">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text class="pa-4">
          <v-form ref="form" lazy-validation>
            <v-row>
              <v-col cols="12">
                <div v-if="form.icon">
                  <v-img :src="form.icon" @click="onButtonClick" />
                </div>
                <div
                  v-else
                  id="upload-image"
                  class="d-flex black--text justify-center align-center"
                  @click="onButtonClick"
                >
                  Upload Image
                </div>
                <input
                  v-show="false"
                  ref="upload"
                  type="file"
                  accept="image/*"
                  @change="onFileChanged"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="form.key"
                  :rules="[(v) => !!v || 'Category must be fill']"
                  name="schoolyear-semester"
                  label="Category"
                  dense
                  outlined
                />
              </v-col>
            </v-row>
            <v-divider class="mb-2" />
            <v-row>
              <v-col class="d-flex justify-center">
                <v-btn
                  :loading="loadingDialog"
                  width="150"
                  color="#04BF68"
                  dark
                  depressed
                  @click="saveCategory()"
                >
                  Save
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <ModalConfirm
      :dialog="dialogConfrim"
      :text="`Delete Category ${deleteData.key}?`"
      :loading="loadingDialog"
      title="Delete Category"
      @action="(r) => deleteAction(r)"
    />

    <view-image
      :viewFile="viewImage"
      :urlFile="urlFile"
      @close="viewImage = false"
    />
  </v-sheet>
</template>

<script>
import {
  masterCategory,
  addMasterCategory,
  editMasterCategory,
  deleteMasterCategory,
} from "@/api/master";
import { isURL } from "@/utils/validate";
import { uploadFile, removeFile } from "@/api/file";

import ModalConfirm from "@/components/ModalConfirm.vue";
import ViewImage from "../../components/ViewImage.vue";

export default {
  components: {
    ModalConfirm,
    ViewImage,
  },
  data() {
    return {
      loading: false,
      loadingDialog: false,
      dialogConfrim: false,
      dialogForm: false,
      viewImage: false,
      search: "",
      urlFile: "",
      data: [],
      deleteData: { key: null, icon: "" },
      total: 0,
      form: {
        key: null,
        oldKey: null,
        icon: null,
        oldImage: null,
        file: null,
      },
      query: {
        page: 1,
        limit: 10,
      },
      isEdit: false,
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "Category",
          value: "key",
        },
        {
          text: "Icon",
          value: "icon",
          width: 150,
        },
        {
          text: "Action",
          value: "action",
          align: "center",
          width: 200,
        },
      ];
    },
  },
  mounted() {
    this.getCategoryList();
  },
  methods: {
    pagination(item) {
      const { page, itemsPerPage } = item;
      this.query.page = page;
      this.query.limit = itemsPerPage === -1 ? 1000 : itemsPerPage;
      this.getCategoryList();
    },
    onFileChanged(e) {
      if (e.target.files.length > 0) {
        if (isURL(this.form.icon)) {
          this.form.oldImage = this.form.icon;
        }
        const file = e.target.files[0];
        this.form.file = file;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          this.form.icon = e.target.result;
        };
      }
    },
    onButtonClick() {
      this.$refs.upload.click();
    },
    async deleteAction(param) {
      if (param) {
        this.loadingDialog = true;
        const { icon, key } = this.deleteData;

        // delete image from server
        if (isURL(icon)) {
          const delImage = await removeFile({ file: icon });
          if (!delImage.data.code) this.snackBar(false, "Failed delete image");
        }

        deleteMasterCategory({ key: key })
          .then((res) => {
            const { data } = res;
            this.snackBar(data.code === 1, data.message);
          })
          .catch(() => this.snackBar(false, "something went wrong"))
          .finally(() => {
            this.loadingDialog = false;
            this.dialogConfrim = false;
            this.deleteData = { key: null, icon: "" };
            this.getCategoryList();
          });
      } else this.dialogConfrim = false;
    },
    closeForm(param) {
      if (param === "save") this.getCategoryList();
      this.dialogForm = false;
      this.isEdit = false;
      this.oldKey = null;
      this.$nextTick(() => {
        this.form.oldImage = null;
        this.form.file = null;
        this.form.icon = null;
        this.form.oldKey = null;
        this.$refs.form.reset();
        this.$refs.form.resetValidation();
      });
    },
    showDialog(item) {
      this.dialogForm = true;
      if (item) {
        this.form.key = item.key;
        this.form.oldKey = item.key;
        this.form.icon = item.icon;
        this.isEdit = true;
      }
    },
    getCategoryList() {
      this.loading = true;
      masterCategory(this.query)
        .then((res) => {
          const { data } = res;
          if (data.code) {
            this.data = data.data.data;
            this.total = data.data.total;
          }
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    },
    deleteHandler(item) {
      this.deleteData = item;
      this.dialogConfrim = true;
    },
    snackBar(isSuccess, msg) {
      this.$store.commit("SET_SNACKBAR", {
        value: true,
        text: msg,
        color: isSuccess ? "success" : "error",
      });
    },
    async saveCategory() {
      this.loadingDialog = true;
      const { key, oldKey, file, icon, oldImage } = this.form;
      let image = icon;

      // Upload image
      if (file) {
        const form = new FormData();
        form.append("file", file);

        const uploadImage = await uploadFile(form);
        if (uploadImage.data.code) {
          image = uploadImage.data.data.path;
        } else this.snackBar(false, "Failed upload image");
      }

      // delete old image, if exist
      if (this.isEdit) {
        if (isURL(oldImage)) {
          const delImage = await removeFile({ file: oldImage });
          if (!delImage.data.code) this.snackBar(false, "Failed delete image");
        }
      }

      if (this.isEdit) {
        editMasterCategory({ key: oldKey, new_key: key, icon: image })
          .then((res) => {
            const { data } = res;
            this.snackBar(true, data.message);
          })
          .catch(() => {
            this.snackBar(false, "something went wrong");
          })
          .finally(() => {
            this.loadingDialog = false;
            this.closeForm("save");
          });
      } else {
        addMasterCategory({ key: key, icon: image })
          .then((res) => {
            const { data } = res;
            this.snackBar(true, data.message);
          })
          .catch(() => {
            this.snackBar(false, "something went wrong");
          })
          .finally(() => {
            this.loadingDialog = false;
            this.closeForm("save");
          });
      }
    },
  },
};
</script>

<style lang="scss">
#upload-image {
  border: 1px solid gray;
  cursor: pointer;
  height: 300px;
}
</style>
